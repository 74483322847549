.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  overflow-x: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #f5f5f5 !important;
  color: #475b63 !important;
  margin: 0;
  //   font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-family: Apron !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #F5F5F5; */
}

body a {
  color: #475b63;
  text-decoration: none;
}

body ul {
  list-style-type: none;
  padding: 0;
}

label {
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.company-slider {
  padding: 20px 0;
  width: 20%;
  display: inline-block;
}
.company-slider .slider-wrap {
  position: relative;
  overflow: hidden;
  height: 280px;
  background-color: white;
  width: 100%;
}

.Carousel__RailWrapper-sc-hyhecw-1 {
  padding-top: 50px;

  @media only screen and (max-width: 370px) {
    padding-top: 5px;
  }

  @media only screen and (max-width: 500px) and (min-width: 370px) {
    padding-top: 15px;
  }

  @media only screen and (min-width: 500px) and (max-width: 760px) {
    padding-top: 45px;
  }
}

.fPimMH {
  background: #fff;
}

.company-slider .button-area {
  text-align: center;
}
.company-slider .slider-wrap .slider-main .item {
  display: inline-block;
  width: 100%;
  height: 280px;
  position: relative;
}
.company-slider .slider-wrap .slider-main .item .body-content .company-name {
  margin-bottom: 0;
  margin-top: 1em;
}

.company-slider .slider-wrap .slider-main .item .body-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 9rem;
}

.company-slider .button-area .btn-light {
  border: 1px solid #eee;
  color: #b9b9b9;
  padding-left: 1em;
  padding-right: 1em;
}

.carousel_comp {
  display: inline-block;
  width: 100%;
  height: 280px;
  position: relative;
}

.car-image {
  //  height: auto;
  //  width: 98%;
  max-width: 100%;
  //  min-height: 150px;
  // max-height: 216px;
  height: 200px;

  @media only screen and (max-width: 991px) {
    // width: 25%;
  }

  @media only screen and (max-width: 767px) {
    // min-height: 90px;
    // max-height: 250px;
    height: 100px;
    max-width: 100% !important;
  }

  @media only screen and (max-width: 425px) {
    // min-height: 80px;
    // max-height: 200px;
    height: 90px;
  }
}

.car-div {
  vertical-align: middle;
  //   max-height: 228px;
}
.company-slider-container .hUEEOJ {
  display: inline-block !important;
  width: 100%;
  height: 280px;
  position: relative;
}
.company-slider-container .jjfwLM {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  /* width: 9rem; */
}
.company-slider-container .bBfHpH {
  left: calc(50% - 36px) !important;
  top: calc(85% - 17.5px) !important;
  border-radius: 1px !important;
  border: 1px solid rgb(215, 214, 214) !important;
  box-shadow: none !important;
}
.company-slider-container .dZkckO {
  right: calc(50% - 36px) !important;
  top: calc(85% - 17.5px) !important;
  border-radius: 1px !important;
  box-shadow: none !important;
  border: 1px solid rgb(215, 214, 214) !important;
}

.cat {
  display: inline-block !important;
  margin: auto 99px !important;
}

.p-100 {
  padding: 4em;
}

footer {
  font-size: 13px;
  background-color: #fff;
}

.home-footer-div {
  padding-top: 3em;
  padding-bottom: 3em;
}

.home-footer-div div {
  //   margin: 0 12px;
  background-color: #fff;
}

.company-slider-container {
  padding-top: 1em;

  h3 {
    text-align: center;
  }

  .company-slider {
    padding: 10px 0 10px 0;
    width: 100%;
    display: inline-block;

    .slider-wrap {
      position: relative;
      overflow: hidden;
      height: 280px;
      background-color: white;
      width: 100%;

      .slider-main {
        position: absolute;
        width: 100%;

        .item {
          display: inline-block;
          width: 20%;
          height: 280px;
          position: relative;

          @media only screen and (max-width: 991px) {
            width: 25%;
          }

          @media only screen and (max-width: 767px) {
            width: 33.3333%;

            h5 {
              font-size: medium;
            }
          }

          @media only screen and (max-width: 575px) {
            width: 33.3333%;
          }

          &:hover,
          &:hover .body-content .btn {
            border: 1px solid #5626c9;
            cursor: pointer;
            visibility: visible;
          }

          .body-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            width: 100%;
            padding: 1rem;

            img {
              max-height: 100px;
              margin-bottom: 1rem;
            }

            .company-name {
              font-weight: 400;
            }

            span {
              display: block;
              font-size: 12px;
            }

            .rating-box {
              display: flex;
              width: 120px;
              margin: auto;
              justify-content: space-evenly;
            }

            .btn {
              margin-top: 0.5em;
              visibility: hidden;
            }
          }
        }
      }
    }

    .button-area {
      text-align: center;

      .btn-light {
        border: 1px solid #eee;
        color: rgb(185, 185, 185);
        padding-left: 1em;
        padding-right: 1em;
      }
    }
  }
}

// .container {
//   padding-top: 2rem;
//   padding-bottom: 1.5rem;
//   width: 95% !important;
//   max-width: 95% !important;
//   overflow: hidden;
// }

.list-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  div {
    &:nth-of-type(1) {
      margin-right: auto;
    }
  }

  .sort-menu {
    margin-left: 1rem;
  }

  span {
    color: #898989;
  }
}

.deliveries-list {
  .brand-card {
    // padding: 1rem 2rem;
    background-color: #fff;
    margin-bottom: 1rem;
    border: 1px solid #e9e9e9;
    cursor: pointer;
    display: flex;
    // min-height: 188px;
    justify-content: space-between;
    position: relative;

    &:hover {
      border: 1px solid #5626c9;
    }
    .text-right {
      text-align: right;
    }
    .text-left {
      text-align: left;
    }
    h5 {
      font-size: 17px;
      margin: 15px 0 15px 0;
    }

    .brand-bottom {
      // color: rgb(255, 255, 255);
      background-color: #e8e8e8;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    @media only screen and (max-width: 991px) {
      display: block;
    }
    @media only screen and (max-width: 480px) {
      display: block;
      text-align: center;
      min-height: 220px;
      h5 {
        font-size: 18px;
        margin: 15px 0 15px 0;
      }
    }

    .image-col {
      //   width: 150px;
      text-align: center;
      vertical-align: top;
      //   margin-right: 1.5rem;
      //   margin-bottom: 2rem;

      @media only screen and (max-width: 991px) {
        display: inline-block;
      }

      img {
        max-height: 100px;
      }
    }

    .company-name {
      //   margin-bottom: 2rem;
      //   max-width: 30%;
      //   // border: 1px solid #000;

      //   @media only screen and (max-width: 991px) {
      //     display: inline-block;
      //     max-width: 60%;
      //   }

      h5 {
        font-weight: 400;
        margin-bottom: 0;
      }

      .type-of-services {
        color: gray;
      }
    }

    .menu-breakdown {
      @media only screen and (max-width: 991px) {
        margin-bottom: 1rem;
      }

      .items-qty {
        font-size: medium;
        color: gray;
      }

      h5 {
        font-weight: 500;
      }

      .menu-category {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
          padding: 5px 10px;
          text-align: center;

          .ng-fa-icon {
            font-size: 2rem;
            color: #61646e;
          }

          h6 {
            margin-bottom: 0;
            font-weight: 400;
          }

          p {
            font-size: small;
            color: gray;
          }
        }
      }
    }

    .menu-genetics {
      padding: 0 1rem 0 0;

      h5 {
        margin-bottom: 0;
        font-weight: 500;

        @media only screen and (max-width: 991px) {
          margin-bottom: 1rem;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;

        @media only screen and (max-width: 991px) {
          display: flex;
          justify-content: space-evenly;
        }

        .item-qty {
          font-size: small;
          color: gray;
        }
      }
    }
  }
}

.brands-list {
  .brand-card {
    // padding: 1rem 2rem;
    background-color: #fff;
    margin-bottom: 1rem;
    // border: 1px solid #e9e9e9;
    cursor: pointer;
    // display: flex;
    text-align: center;
    height: 310px;
    // justify-content: space-between;
    position: relative;

    // &:hover {
    //   border: 1px solid #5626c9;
    // }
    .text-right {
      text-align: right;
    }
    .text-left {
      text-align: left;
    }
    h5 {
      font-size: 17px;
      margin: 15px 0 15px 0;
    }

    .brand-bottom {
      // color: rgb(255, 255, 255);
      background-color: #e8e8e8;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    @media only screen and (max-width: 991px) {
      display: block;
    }
    @media only screen and (max-width: 480px) {
      display: block;
      text-align: center;
      min-height: 220px;
      h5 {
        font-size: 18px;
        margin: 15px 0 15px 0;
      }
    }

    .image-col {
      //   width: 150px;
      text-align: center;
      vertical-align: top;
      //   margin-right: 1.5rem;
      //   margin-bottom: 2rem;

      @media only screen and (max-width: 991px) {
        display: inline-block;
      }

      img {
        max-height: 100px;
      }
    }

    .company-name {
      //   margin-bottom: 2rem;
      //   max-width: 30%;
      //   // border: 1px solid #000;

      //   @media only screen and (max-width: 991px) {
      //     display: inline-block;
      //     max-width: 60%;
      //   }

      h5 {
        font-weight: 400;
        margin-bottom: 0;
      }

      .type-of-services {
        color: gray;
      }
    }

    .menu-breakdown {
      @media only screen and (max-width: 991px) {
        margin-bottom: 1rem;
      }

      .items-qty {
        font-size: medium;
        color: gray;
      }

      h5 {
        font-weight: 500;
      }

      .menu-category {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
          padding: 5px 10px;
          text-align: center;

          .ng-fa-icon {
            font-size: 2rem;
            color: #61646e;
          }

          h6 {
            margin-bottom: 0;
            font-weight: 400;
          }

          p {
            font-size: small;
            color: gray;
          }
        }
      }
    }

    .menu-genetics {
      padding: 0 1rem 0 0;

      h5 {
        margin-bottom: 0;
        font-weight: 500;

        @media only screen and (max-width: 991px) {
          margin-bottom: 1rem;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;

        @media only screen and (max-width: 991px) {
          display: flex;
          justify-content: space-evenly;
        }

        .item-qty {
          font-size: small;
          color: gray;
        }
      }
    }
  }
}

.dispensaries-list {
  .brand-card {
    // padding: 1rem 2rem;
    background-color: #fff;
    margin-bottom: 1rem;
    border: 1px solid #e9e9e9;
    cursor: pointer;
    display: flex;
    // min-height: 188px;
    justify-content: space-between;
    position: relative;

    &:hover {
      border: 1px solid #5626c9;
    }
    .text-right {
      text-align: right;
    }
    .text-left {
      text-align: left;
    }
    h5 {
      font-size: 17px;
      margin: 15px 0 15px 0;
    }

    .brand-bottom {
      // color: rgb(255, 255, 255);
      background-color: #e8e8e8;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    @media only screen and (max-width: 991px) {
      display: block;
    }
    @media only screen and (max-width: 480px) {
      display: block;
      text-align: center;
      min-height: 220px;
      h5 {
        font-size: 18px;
        margin: 15px 0 15px 0;
      }
    }

    .image-col {
      //   width: 150px;
      text-align: center;
      vertical-align: top;
      //   margin-right: 1.5rem;
      //   margin-bottom: 2rem;

      @media only screen and (max-width: 991px) {
        display: inline-block;
      }

      img {
        max-height: 100px;
      }
    }

    .company-name {
      //   margin-bottom: 2rem;
      //   max-width: 30%;
      //   // border: 1px solid #000;

      //   @media only screen and (max-width: 991px) {
      //     display: inline-block;
      //     max-width: 60%;
      //   }

      h5 {
        font-weight: 400;
        margin-bottom: 0;
      }

      .type-of-services {
        color: gray;
      }
    }

    .menu-breakdown {
      @media only screen and (max-width: 991px) {
        margin-bottom: 1rem;
      }

      .items-qty {
        font-size: medium;
        color: gray;
      }

      h5 {
        font-weight: 500;
      }

      .menu-category {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
          padding: 5px 10px;
          text-align: center;

          .ng-fa-icon {
            font-size: 2rem;
            color: #61646e;
          }

          h6 {
            margin-bottom: 0;
            font-weight: 400;
          }

          p {
            font-size: small;
            color: gray;
          }
        }
      }
    }

    .menu-genetics {
      padding: 0 1rem 0 0;

      h5 {
        margin-bottom: 0;
        font-weight: 500;

        @media only screen and (max-width: 991px) {
          margin-bottom: 1rem;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;

        @media only screen and (max-width: 991px) {
          display: flex;
          justify-content: space-evenly;
        }

        .item-qty {
          font-size: small;
          color: gray;
        }
      }
    }
  }
}

.company-page-container {
  //padding-bottom: 1em;
  // padding: 0 2%;
  padding: 60px 2%;
  .page-header {
    text-align: center;
    background-color: black;
    color: white;
    padding: 0 1em 2em 1em;
    border-bottom: 1px solid rgb(63, 63, 63);

    .image-name-box {
      margin: auto;
      max-width: 220px;
      max-height: 220px;
      margin-bottom: 1em;

      .image-box {
        background-color: #fff;
        padding: 10px;
      }

      .name-box {
        background-color: #bacdb0;
        padding: 2px 0;
        color: #333;
      }
    }

    span {
      display: block;
      font-size: 14px;
    }
  }

  .page-menu {
    background-color: #fff;
    padding: 5px 14px 14px 14px;

    .nav-item {
      padding: 0 1em;

      .nav-link {
        color: #333;
      }

      .router-link-active {
        border-bottom: #729b79 3px solid;
      }
    }
  }
}

nav {
  background-color: #fff;
  margin-bottom: 1rem;
  box-shadow: 0 0 10px 15px rgba(61, 46, 46, 0.02);

  .navbar-brand {
    position: absolute;
    top: 5.5px;
    left: 50%;
    transform: translate(-50%, 0);

    @media only screen and (max-width: 426px) {
      position: absolute;
      top: 12.5px;
      left: 34%;
    }

    @media only screen and (max-width: 750px) {
      position: absolute;
      top: 5.5px;
      left: 30%;
    }

    img {
      width: 160px;
      @media only screen and (max-width: 479px) {
        width: 80px;
        margin-top: 5px;
      }
    }
  }

  .main-nav {
    a.nav-link {
      color: #475b63;
      font-weight: 500;
      font-size: 17px;
      // border-bottom: 1px solid #f2f2f2;
    }
  }

  .form-control {
    background-color: #f5f5f5;
    border: none;
    border-radius: 5px !important;
    width: 320px;
  }

  .ms-n5 {
    margin-left: -50px;
  }

  .right-nav {
    a {
      font-weight: 500;
    }

    .btn {
      margin-left: 10px;
      font-weight: 500;

      @media only screen and (max-width: 991px) {
        margin-left: 0;
        margin-top: 1em;
        width: 100%;
      }
    }
  }
}

body {
  background-color: #f5f5f5;
  color: #475b63;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  a {
    color: #475b63;

    &:hover {
      color: #39484e;
    }
  }

  .btn {
    border-radius: 0;
  }

  .ng-fa-icon {
    color: #475b63;
  }
}

.black-text {
  color: #000 !important;
}

.form-control:focus {
  border-color: #5626c9;
  box-shadow: 0 0 0 0.1rem #5626c9;
}

.carousel-indicators [data-bs-target] {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.carousel-control-prev-icon {
  height: 40px;
  width: 40px;
  background-image: url("./assets/images/circle-chevron-left-solid.svg");
}

.carousel-control-next-icon {
  height: 40px;
  width: 40px;
  background-image: url("./assets/images/circle-chevron-right-solid.svg");
}

.map-container {
  width: 100% !important;
  max-width: 100% !important;
}
.map-dispensaries {
  overflow: scroll;
  height: 90vh;
  // max-height: 500px;

  // .brand-card {
  //     font-size: 12px;
  //     padding: 1rem 1rem;
  //     background-color: #fff;
  //     margin-bottom: 1rem;
  //     cursor: pointer;
  //     display: flex;
  //     justify-content: space-between;

  //     &:hover {
  //       border: 1px solid #5626C9;
  //     }

  //     @media only screen and (max-width: 991px) {
  //     //   display: block;
  //     //   text-align: center;
  //     //   justify-content: center !important;
  //     }

  //     .image-col {
  //       max-width: 120px;
  //       text-align: center;
  //       vertical-align:top;
  //       margin-right: 1.5rem;
  //       margin-bottom: 2rem;

  //       @media only screen and (max-width: 991px) {
  //         display: inline-block;
  //         margin-right: 0;
  //       }

  //       img {
  //         max-height: 100px;
  //       }
  //     }
  // }

  .brand-card {
    .view-detail {
      display: none;
    }
    font-size: 12px;
    padding: 1rem 1rem;
    background-color: #fff;
    margin-bottom: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &:hover {
      .view-detail {
        display: block;
      }

      border: 1px solid #5626c9;
    }
    .text-right {
      text-align: right;
    }
    .text-left {
      text-align: left;
    }
    // h5{
    //     font-size: 17px;
    //     margin: 15px 0 15px 0;
    // }

    .brand-bottom {
      // color: rgb(255, 255, 255);
      background-color: #e8e8e8;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }

    @media only screen and (max-width: 992px) {
      display: block;
      text-align: center;
    }
    @media only screen and (max-width: 480px) {
      display: block;
      text-align: center;
      // min-height: 220px;
      h5 {
        font-size: 18px;
        margin: 15px 0 15px 0;
      }
    }

    .image-col {
      //   width: 150px;
      text-align: center;
      vertical-align: top;
      //   margin-right: 1.5rem;
      //   margin-bottom: 2rem;

      @media only screen and (max-width: 991px) {
        display: inline-block;
      }

      img {
        max-height: 100px;
      }
    }

    .company-name {
      //   margin-bottom: 2rem;
      //   max-width: 30%;
      //   // border: 1px solid #000;

      //   @media only screen and (max-width: 991px) {
      //     display: inline-block;
      //     max-width: 60%;
      //   }

      h5 {
        font-weight: 400;
        margin-bottom: 0;
      }

      .type-of-services {
        color: gray;
      }
    }

    .menu-breakdown {
      @media only screen and (max-width: 991px) {
        margin-bottom: 1rem;
      }

      .items-qty {
        font-size: medium;
        color: gray;
      }

      h5 {
        font-weight: 500;
      }

      .menu-category {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
          padding: 5px 10px;
          text-align: center;

          .ng-fa-icon {
            font-size: 2rem;
            color: #61646e;
          }

          h6 {
            margin-bottom: 0;
            font-weight: 400;
          }

          p {
            font-size: small;
            color: gray;
          }
        }
      }
    }

    .menu-genetics {
      padding: 0 1rem 0 0;

      h5 {
        margin-bottom: 0;
        font-weight: 500;

        @media only screen and (max-width: 991px) {
          margin-bottom: 1rem;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;

        @media only screen and (max-width: 991px) {
          display: flex;
          justify-content: space-evenly;
        }

        .item-qty {
          font-size: small;
          color: gray;
        }
      }
    }
  }
}

.gmap {
  min-height: 500px;
}

.justify-category {
  justify-content: start !important;
  @media only screen and (max-width: 991px) {
    // justify-content: center !important;
  }
  @media only screen and (max-width: 991px) {
    justify-content: center !important;
  }
}
.mr-lg-1 {
  margin-right: 1.5rem !important;
}

.ml-lg-1 {
  margin-left: 1.5rem !important;
}

.company-list-container {
  // border: 1px solid #000;
  height: 91.6vh;
  overflow: hidden;
  overflow-y: hidden;
  direction: rtl;
  border-right: 1px solid #f5f5f5;
  position: relative;

  .viewport {
    height: 92vh;
    width: 25vw;
    // border: 1px solid black;

    @media only screen and (max-width: 767px) {
      width: 100vw;
    }

    .company-list-card {
      background-color: #fff;
      padding: 2em 1em;
      margin-bottom: 0.2em;
      box-shadow: 0 0 6px 15px rgba(0, 0, 0, 0.01);
      direction: ltr;

      .grey-text {
        color: #666;
      }

      span {
        display: block;
        font-size: 14px;
      }

      &:hover {
        border: 1px solid #5626c9;
        cursor: pointer;
      }

      img {
        width: 100%;
      }

      .company-details-wrapper {
        h5 {
          margin: 0;
        }

        .available-services-wrapper {
          .ng-fa-icon {
            margin-right: 1em;
            color: #7452c4;
          }
        }
      }

      .reviews-section {
        margin-bottom: 0.4em;
      }

      .menu-breakdown-title-section {
        h5 {
          .qty-items {
            display: inline-block;
            font-size: 14px;
            color: #666;
          }
        }
      }

      .menu-breakdown-wrapper {
        display: flex;
        justify-content: space-evenly;

        .item {
          text-align: center;

          .ng-fa-icon {
            color: #666;
            font-size: 20px;
          }
        }
      }

      .menu-genetics-wrapper {
        h6 {
          margin: 0;
        }
      }
    }
  }
}

.my-custom-scrollbar {
  position: relative;
  width: 800px;
  height: 400px;
  overflow: auto;
}

.locationSearchBox {
  text-align: left;
  margin-right: 10%;
  width: 100% !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 0.375rem 0.75rem !important;
}
.navbar-nav .nav-item {
  display: inline-block;
}
.mapBox {
  min-height: 500px;
}

.img-fluid {
  max-width: 100% !important;
}
.navbar-light .navbar-toggler {
  box-shadow: none;
  // border: 1px solid #898989 !important;
}

header {
  // z-index: 9999;
  // position: sticky;
  // top: 0;

  form {
    @media only screen and (max-width: 750px) {
      // justify-content: center;
      // width: 100%;
      // display: flex;
      // padding: 2%;
      // padding-right: 0%;
      justify-content: end;
      width: 55%;
      display: flex;
      padding: 2%;
      padding-right: 0%;
    }

    @media only screen and (max-width: 426px) {
      width: 50%;
      .search_cus {
        justify-content: flex-end;
        position: fixed;
        right: 10px;
        top: 2px;
      }
    }
  }
}

// .cSNndH {
//     @media screen and (max-width: 767px){
//         display: block;
//     }
// }

.view-all-link {
  // @media screen and (max-width: 767px){
  display: none;
  // }
}

.jjfwLM {
  // text-align: left !important;
}

.txt_head {
  color: #252935;
  // font-family: Apron;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  font-weight: 700;
  -webkit-letter-spacing: 0.00625rem;
  -moz-letter-spacing: 0.00625rem;
  -ms-letter-spacing: 0.00625rem;
  letter-spacing: 0.00625rem;
  line-height: 1.25rem;
}

.txt-view-all {
  margin-right: 0.6rem !important;
  font-size: 0.9rem;
}

.statusTxt {
  display: flex;
  justify-content: center;
  padding: 5%;
}

.link {
  color: #3063a5;
}

.tyDGf {
  display: block;

  @media only screen and (max-width: 750px) {
    display: none;
  }
}

.tyDGf2 {
  display: none;

  @media only screen and (max-width: 750px) {
    display: block;
  }
}

.w-20 {
  width: 20% !important;
}

.slider-container {
  flex-wrap: inherit;
  overflow:hidden;
  .car-item {
    width: 20%;
    @media only screen and (max-width: 370px) {
      min-width: 50% !important;
    }

    @media only screen and (max-width: 900px) and (min-width: 370px) {
      min-width: 33% !important;
      // display: none;
    }

    @media only screen and (min-width: 900px) and (max-width: 1024px) {
      width: 25% !important;
    }
  }
}

.search_cus {
  justify-content: flex-end;
  position: fixed;
  right: 10px;
  top: 10px;
}

.ptrem {
  padding-top: 1.5rem !important;
}

.header-location {
  background: whitesmoke;
}

//custom

.navbar-nav {
  .nav-link {
    padding-left: 15px;
  }
}

.navbar-close-icon {
  svg {
    font-size: 30px !important;
    padding-left: 2px;
  }
}

@media only screen and (max-width: 767px) {
  .search_cus {
    justify-content: flex-end;
    position: fixed;
    right: 10px;
    top: 2px !important;
  }
}

@media screen and (min-width: 480px) and (max-width: 639px) {
  nav .navbar-brand img {
    width: 100px !important;
  }
  .navbar-nav .nav-item {
    display: inline-block !important;
    border: 1px solid !important;
  }
}
@media screen and (min-width: 640px) and (max-width: 767px) {
  nav .navbar-brand img {
    width: 100px !important;
  }
}
